<template>
  <div class="bg" id="faq">
    <div class="container px-2 py-5 overflow-hidden text-blue">
      <div class="row">
        <div class="col-md-6 col-lg-4 p-0 d-none d-md-block align-self-end">
          <img class="w-100" src="/assets/faq.png" alt="">
        </div>
        <div class="col-md-6 col-lg-8">
            <h2>Вопрос-ответ</h2>
            <div class="qa"  v-for="(v, k) in qa" :key="k">
              <p class="mb-1 cursor-pointer" @click="active=k">
                <strong class="point mr-1 mb-1" v-text="k+1" :class="active == k ? ['active'] : ''"></strong> <strong v-text="v.q"></strong>
                <!-- <span class="badge bg-warning" v-if="active != k">-></span> -->
              </p>
              <transition name="show" mode="out-in">
                <p class="mb-3" v-html="v.a" v-if="active == k"></p>
              </transition>
            </div>
            <p class="my-3 border-top bg-warning p-3 text-dark">Не нашли ответ на свой вопрос?
              <a href="#" @click.prevent="$parent.show_modalorder('Запрос консультации')">Проконсультируйтесь</a>
              у нашего специалиста
            </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Faq",
  props: { },
  data: () => {
    return {
      qa: [
        {
          'q':'Может ли делать техническое обслуживание ВДГО (ВКГО) не только АО «Ставропольгоргаз»?',
          'a':'Да, требуется только АДС или соглашение на АДС, аттестованный персонал, а так же оборудование и круглосуточная диспетчеризация.'
        },
        {
          'q':'Насколько это законно и не будет ли потом проблем?',
          'a':'Нет, проблем не будет, так как вся информация о проведенном ТО передается АО «Ставропольгоргаз».'
        },
        {
          'q':'Какие нужны документы чтобы вызвать мастер и что он даст после проведенных работ?',
          'a':'Для проведения работ по техническому обслуживанию ВДГО (ВКГО) от заказчика требуется паспорт (РФ) владельца квартиры или дома.<br> После проведения работ, у клиента остается договор на техническое обслуживание внутридомового газового оборудования и аварийно-диспетчерское обеспечение, Акт сдачи-приемки выполненных работ, перечень выполненных работ и справка.'
        },
        {
          'q':'Регистрируются ли эти действия в каких-нибудь государственных реестрах?',
          'a':'После проведения технического обслуживания составляется реестр, в котором указывается: номер договора, дата проведения ТО, адрес и какое газовое оборудование установлено.'
        },
        {
          'q':'Как часто реестр передают в АО «Ставропольгоргаз»?',
          'a':'1 раз в 2 недели.'
        },
        {
          'q':'На какой срок заключается договор?',
          'a':'На 1 год.'
        },
        {
          'q':'Есть ли вас запчасти для котлов?',
          'a':'Да, есть большой склад запчастей, а так же работает собственный ремонтно-восстановительный цех (лаборатория).'
        }
      ],
      active: 0
    }
  },
  methods:{
  }
};
</script>


<style scoped lang="scss">
  .bg-salmon{
    background-color:#ffc9c9 !important
  }

  .border-warning{
    border: 3px dashed #000;
  }

  .point{
    border: 1px solid #007ac2;
    line-height: 1;
    padding: 11px;
    border-radius: 100%;
    width: 35px;
    height: 35px;
    display: inline-block;
    text-align: center;
    padding-top: 10px;
    transition: all ease .3s;
    &.active{
      background-color: #007ac2;
      color: #fff;
    }
  }
</style>
