<template>
  <div class="container p-0 overflow-hidden" id="header">
    <div class="row">
      <div class="col-9 col-md-5 bg-blue order-0 order-md-0">
        <div class="logo p-2 d-flex">
          <img src="/assets/logo-white.png" class="mr-2" alt="">
          <span class="text-white">
            <span class="d-block font-weight-bold mt-1 mb-0 vdgo lh-1">ВДГО</span>
            <span class="city ">Ставрополь</span> 
          </span>
        </div>
      </div>
      <div class="col-12 col-md-6 order-2 order-md-1">
        <div class="row">
          <div class="col-12 col-xl-7">
            <div class="p-2 p-md-3 text-center">
              <p class="phone h3 m-0">            
                <a class="no-wrap text-decoration-none text-blue" :href="`tel:${contacts.phone}`" v-text="contacts.phone"></a>
                <span class="d-block font-weight-light small ь-0">телефон горячей линии</span>
              </p>          
            </div>                
          </div>

          <div class="col-xl-5 d-none d-xl-flex justify-content-center align-items-center">
            <button class="btn btn-warning btn-outline-dark btn-lg my-2" @click="$parent.show_modalorder('Записаться на ТО')">Записаться на ТО</button>
          </div>
        </div>
    
      </div>
      <div class="col-3 col-md-1 p-0 order-1 order-md-2">
        <div class="wr-burger h-100 w-100 d-flex align-items-center justify-content-center" @click="toggle_menu()">
          <div class="burger" :class="{active:active_menu}">
            <span></span>
            <span></span>
            <span></span>
          </div>          
        </div>
      </div>
    </div>
    <div class="row bg-blue-dotted">
      <div class="col-12 p-3">
        <p class="px-3 m-0 text-white font-weight-bold d-flex flex-wrap">          
          <span class="flex-grow-1">Техническое обслуживание внутридомового газового оборудования в городе Ставрополе.</span>
          <span class="ml-2 d-none d-xl-inline">Официально</span>
          <span class="ml-2 d-none d-xl-inline">Оперативно</span>
          <span class="ml-2 d-none d-xl-inline">Качественно</span>
        </p>        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: {
    contacts: Object,
    active_menu: Boolean
  },
  methods:{
    toggle_menu: function(){
      this.$emit('menu_status', !this.active_menu) 
    }
  }
};
</script>


<style scoped lang="scss">
.burger{
  transition: opacity .3s ease-in-out;
  cursor: pointer;
  z-index: 999;

  span{
    transition: all .3s ease-in-out;
    background: #1b86c0;
    position: relative;
    z-index: 999;
    display: block;
    width: 26px;

    &:nth-child(1){
      margin-bottom: 5px;
      height: 3px;
    }
    &:nth-child(2){
        margin-bottom: 5px;
        height: 3px;
    }
    &:nth-child(3){
        margin-bottom: 5px;
        height: 3px;
    }
  }

  &.active{
    span{
      background-color: #eaf6fd;
      &:nth-child(1){
        transform: rotate(45deg) translate3d(1px,10px,0);
      }
      &:nth-child(2){
        opacity: 0;
      }
      &:nth-child(3){
        transform: rotate(-45deg) translate3d(1px,-10px,0);
      }      
    }

  }
}

.logo{
  user-select: none;
  .vdgo{
    font-size: 2.5rem;
  }
  .city{
    font-size: 1.5rem;
  }
  img{
    width:80px;
  }
}
</style>
