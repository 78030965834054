<template>
  <div id="menu" @click="$emit('menu_status', false)">

    <transition :duration="150" name="show">
      <div class="gwr d-flex flex-column shadow-lg" v-if="active_menu">
        <ul class="p-5 flex-grow-1">
          <li><a href="#" @click="$parent.scroll('header')">Главная</a></li>
          <li><a href="#" @click="$parent.scroll('info')">Зачем делать ВДГО?</a></li>
          <li><a href="#" @click="$parent.scroll('calc')">Рассчитать стоимость</a></li>
          <li><a href="#" @click="$parent.scroll('faq')">Вопрос - ответ</a></li>
          <li><a href="#" @click="$parent.scroll('contacts')">Контактная информация</a></li>
        </ul>
        <div class="contacts">
        <ul  class="pb-5 text-center">
          <li><a class="mr-4 text-white" :href="`tel:${contacts.phone}`" v-text="contacts.phone"></a></li>
        </ul>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Menu',
  props: {
    active_menu: Boolean,
    contacts: Object,
  }
}
</script>


<style scoped lang="scss">
  #menu{
  overflow: hidden;
  position: fixed !important;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #061727e3;
  z-index: 1;
  .gwr{
      height: 100%;
      width: 100%;
      max-width: 420px;
      background: linear-gradient(to right,#007ac2 150%,#3a5a5f 100%);
      ul{
        list-style: none;
        li{
          font-size: 1.2rem;
          margin-top: 1rem;
          a{
            color: #fff;
            text-decoration: none;
          }
        }
      }
      .contacts{
        a{
            color: #fff;
            text-decoration: none;
        }
      }
    }
  }
</style>