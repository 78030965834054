<template>
  <div class="bg" id="calc">
    <div class="container px-2 py-5 overflow-hidden text-blue">
      <div class="row">
        <div class="col-12 catalog">
            <h2>Выберите ваше газовое оборудование:</h2>
            <div class="row g-1">
              <div class="col-4 col-lg bg-white">
                <button class="btn btn-outline-primary btn-block btn-lg d-flex flex-column align-content-center align-items-center h-100 justify-content-evenly" @click="show_modalPrice('gnsk')">
                  <img src="/assets/calc/gnsk.png" alt="">
                  <span class="lh-1 mt-1">Настенный котел </span> <!-- 2000 -->         
                </button>                
              </div>
              <div class="col-4 col-lg bg-white">
                <button class="btn btn-outline-primary btn-block btn-lg d-flex flex-column align-content-center align-items-center h-100 justify-content-evenly" @click="show_modalPrice('gnpk')">
                  <img src="/assets/calc/gnpk.png" alt="">
                  <span class="lh-1 mt-1">Напольный котел</span> <!-- 1500 -->         
                </button>                
              </div>
              <div class="col-4 col-lg bg-white">
                <button class="btn btn-outline-primary btn-block btn-lg d-flex flex-column align-content-center align-items-center h-100 justify-content-evenly" @click="show_modalPrice('gk')">
                  <img src="/assets/calc/gk.png" alt="">
                  <span class="lh-1 mt-1">Газовая колонка </span> <!-- 600 -->         
                </button>               
              </div>
              <div class="col-4 col-lg bg-white">
                <button class="btn btn-outline-primary btn-block btn-lg d-flex flex-column align-content-center align-items-center h-100 justify-content-evenly" @click="show_modalPrice('gp')">
                  <img src="/assets/calc/gp.png" alt="">
                  <span class="lh-1 mt-1">Газовая плита </span> <!-- 4 / 300 -->         
                </button>                  
              </div>   
              <div class="col-4 col-lg bg-white">
                <button class="btn btn-outline-primary btn-block btn-lg d-flex flex-column align-content-center align-items-center h-100 justify-content-evenly" @click="show_modalPrice('grpsh')">
                  <img src="/assets/calc/grpsh.png" alt="">
                  <span class="lh-1 mt-1">ГРПШ </span> <!-- 200 -->         
                </button>
              </div>
              <div class="col-4 col-lg bg-white">
                <button class="btn btn-outline-primary btn-block btn-lg d-flex flex-column align-content-center align-items-center h-100 justify-content-evenly" @click="show_modalPrice('other')">
                  <img src="/assets/calc/other.png" alt="">
                  <span class="lh-1">Другое </span> <!-- 200 -->         
                </button>
              </div>         
            </div>
        </div>
        <div class="col-12 result">
          <transition name="show" mode="out-in">
          <div class="bg-warning p-3 text-dark my-3" v-if="!selected_price.length" key="g1">
            Для расчета стоимости ВДГО выберите оборудование из списка выше.
          </div>
          <div class="bg-blue-dotted p-3 text-dark my-3" v-else key="g2">
            <table class="table text-white text-shadow-blue mb-0">
              <tr v-for="(v, k) in selected_price" :key="k">
                <td class="p-1 lh-1 w-100" v-text="v.name"></td>
                <td class="p-1 lh-1 no-wrap text-right">
                  <span v-text="number_format(v.price) + ` руб.`"></span>
                  <span class="badge bg-danger small cursor-pointer" title="Удалить" @click="selected_price.splice(k, 1)">x</span>
                </td>
              </tr>           
              <tr>
                <td class="py-2 px-1 lh-1 font-weight-bold">Итого</td>
                <td class="py-2 px-1 lh-1 no-wrap font-weight-bold" v-text="number_format(summ) + ` руб.`"></td>
              </tr>
              <tr>
                <td class="py-2 px-1 lh-1 font-weight-bold text-warning">Итого со скидкой <span class="p-0" v-text="discount_percent"></span>% при заказе с сайта</td>
                <td class="py-2 px-1 lh-1 no-wrap font-weight-bold text-warning" v-text="number_format(summ_with_discount) + ` руб.`"></td>
              </tr>
            </table>
            <transition name="fade`" mode="out-in">
              <div class="text-center" v-if="summ >= 500">
                <button class="btn-warning btn-lg px-4" @click="$parent.show_modalorder('Заказать ВДГО со скидкой', selected_price)">Заказать ВДГО со скидкой</button>
              </div>
            </transition>
          </div>     
          </transition>
        </div>
      </div>
    </div>



    <div id="modalPrice" class="modal fade" tabindex="-1">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Выберите ваше оборудование из списка:</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="modalPrice.hide()"></button>
          </div>
          <div class="modal-body">
            <table class="table" v-if="Object.keys(price).length">
              <tr v-for="(v, k) in price[active_price_type]" :key="k">
                <td class="p-1 lh-1 w-100" v-text="v.name"></td>
                <td class="p-1 lh-1 no-wrap text-right" v-text="number_format(v.price) + ` руб.`"></td>
                <td class="p-1 lh-1">
                  <button class="btn btn-light btn-outline-primary" @click="selected_price.push(v); modalPrice.hide()">Добавить</button>
                </td>
              </tr>
            </table>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary"  @click="modalPrice.hide()">Закрыть окно</button>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import axios from 'axios'
import bootstrap from 'bootstrap/dist/js/bootstrap.min.js'

export default {
  name: "Calc",
  props: { },
  data(){
    return {
      price: {}, // полученный прайс
      modalPrice: {}, // управление модальным окном
      active_price_type: null, // выбранный тип оборудования
      selected_price: [], // выбранный список оборудования для обслуживания
      summ: 0,
      discount_percent: 10,
      summ_with_discount: 0
    }
  },
  watch:{
    selected_price(){
      this.make_summ()
    }
  },
  methods:{
    make_summ(){
      // console.log(this.selected_price)
      const s = this.selected_price.reduce((a, v)=>({price : +a.price + +v.price}))
      this.summ = s.price
      this.summ_with_discount = Math.ceil(s.price - s.price * this.discount_percent / 100)
    },
    show_modalPrice(type_eq){
      this.active_price_type = type_eq
      this.modalPrice.show()
    },
    number_format(number, round = 0){
      if (round == 0){
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      } else {
        return number.toFixed(round).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      }
    },
  },
  created(){
    axios.get('/price.json').then((r)=>{
      this.$set(this, 'price', r.data)
      //console.log (r.data)
    })
  },
  mounted(){
    this.modalPrice = new bootstrap.Modal(document.getElementById('modalPrice'), { keyboard: false })
  }
};
</script>


<style scoped lang="scss">
  .catalog{
    button{
      img{
        width:100px;
      }
    }
  }

  .text-shadow-blue{
    text-shadow: 0 0 4px #07375b;
  }

  .bg-salmon{
    background-color:#ffc9c9 !important
  }

  .border-werning{
    border: 3px dashed #000;
  }
  .photo{
    max-height: 200px;
    img{
      width:100%;
      height:100%;
    }
  }


.btn-close {
    outline: none;
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: .25em .25em;
    color: #595959;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23595959'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
    border: 0;
    border-radius: .25rem;
    opacity: .5;
    &:hover {
      color: #ddd;
      text-decoration: none;
      opacity: .75;
    }
}
</style>
