<template>
  <div class="bg" id="about">
    <CoolLightBox :items="gallery.items" :index="gallery.index" @close="gallery.index = null" :loop="true"></CoolLightBox>
    <div class="container px-2 py-5 overflow-hidden text-blue">
      <div class="row">
        <div class="col-12">
          <h3>О нас</h3>
        </div>
        <div class="col-12 col-lg-5">
          <div class="row g-1 overflow-hidden">
            <div class="col-8 in">
                <img class="w-100 cursor-pointer" src="/assets/gal/1_thm.jpg" alt="" @click="gallery.index=0">
            </div>
            <div class="col-4">
              <div class="row">
                <div class="col-12 mb-1 in">
                    <img class="w-100 cursor-pointer" src="/assets/gal/2_thm.jpg" alt="" @click="gallery.index=1">
                </div>
                <div class="col-12 in">
                    <img class="w-100 cursor-pointer" src="/assets/gal/3_thm.jpg" alt="" @click="gallery.index=2">
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="col-lg-7 mt-3 mt-lg-0">
          <p class="mb-1">Наша организация существует на рынке уже более 9 лет, последние 2 года занимаемся не только установкой, подключением и ремонтом газовых котлов, но и осуществляем техническое обслуживание котельных, на данный момент их более 85-ти по всему югу России.</p>
          <p class="mb-1">В конце 2019 года расшили штат сотрудников до 35 человек и приняли решение заниматься техническим обслуживанием ВДГО (ВКГО), а так же ШРП, ГРПШ, заменой счетчиков и надземными и подземными газопроводами и не разу об этом не пожалели. С 2020 года по 2021 г. нами было заключено более 1500 абонентских договоров, заменено более 250 газовых счетчиков и заключено 85 договоров на обслуживание котельных.</p>
          <p class="mb-1">Мы не гонимся за количеством, для нас главное качество, поэтому в нашей организации работают сотрудники с высокой классификацией. Для изучения нового модельного ряда котлов и получения знаний и сертификатов, наши специалисты посещают семинары. </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox'

import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {
  name: "About",
  props: { },
  components: {
    CoolLightBox
  },
  data: ()=>{
    return {
      gallery: {
        items: [
          '/assets/gal/1.jpg', '/assets/gal/2.jpg', '/assets/gal/3.jpg'
        ],
        index:null,
      }
    }
  },
  methods:{
  }
};
</script>


<style scoped lang="scss">
.in{
    filter: brightness(80%);
    transition: all ease .5s;
  &:hover{
      filter: brightness(100%);
      transform: scale(1.1);
      z-index: 5;
      transition: all ease .5s;
    
  }
}

</style>
