<template>
  <div class="bg" id="contacts">
    <div class="container px-2 pt-5 overflow-hidden text-blue">
      <div class="row">
        <div class="col-md-6 d-flex flex-column">
          <div class="block_top flex-grow-1">
            <h2>Контакты</h2>
            <p class="h4">Свяжитесь с нами любым удобным способом</p>
            <p class="mb-1">
              <svg data-v-525bdf44="" viewBox="0 0 405.333 405.333" class="mr-3"><use data-v-525bdf44="" xlink:href="#i-phone"><symbol data-v-525bdf44="" id="i-phone" viewBox="0 0 405.333 405.333"><path data-v-525bdf44="" d="M373.333,266.88c-25.003,0-49.493-3.904-72.704-11.563c-11.328-3.904-24.192-0.896-31.637,6.699l-46.016,34.752c-52.8-28.181-86.592-61.952-114.389-114.368l33.813-44.928c8.512-8.512,11.563-20.971,7.915-32.64C142.592,81.472,138.667,56.96,138.667,32c0-17.643-14.357-32-32-32H32C14.357,0,0,14.357,0,32c0,205.845,167.488,373.333,373.333,373.333c17.643,0,32-14.357,32-32V298.88C405.333,281.237,390.976,266.88,373.333,266.88z"></path></symbol></use></svg>
              <a :href="`tel:${contacts.phone}`" v-text="contacts.phone"></a>
            </p>
            <p class="mb-1">
              <svg data-v-525bdf44="" viewBox="0 0 230.17 230.17" class="mr-3"><use data-v-525bdf44="" xlink:href="#i-mail-fill"><symbol data-v-525bdf44="" id="i-mail-fill" viewBox="0 0 230.17 230.17"><path data-v-525bdf44="" d="M230,49.585c0-0.263,0.181-0.519,0.169-0.779l-70.24,67.68l70.156,65.518c0.041-0.468-0.085-0.94-0.085-1.418V49.585z"></path><path data-v-525bdf44="" d="M149.207,126.901l-28.674,27.588c-1.451,1.396-3.325,2.096-5.2,2.096c-1.836,0-3.672-0.67-5.113-2.013l-28.596-26.647L11.01,195.989c1.717,0.617,3.56,1.096,5.49,1.096h197.667c2.866,0,5.554-0.873,7.891-2.175L149.207,126.901z"></path><path data-v-525bdf44="" d="M115.251,138.757L222.447,35.496c-2.427-1.443-5.252-2.411-8.28-2.411H16.5c-3.943,0-7.556,1.531-10.37,3.866
        L115.251,138.757z"></path><path data-v-525bdf44="" d="M0,52.1v128.484c0,1.475,0.339,2.897,0.707,4.256l69.738-67.156L0,52.1z"></path></symbol></use></svg>
              <a :href="`mailto:${contacts.phone}`" v-text="contacts.email"></a>
            </p>
            <p>
              <svg data-v-525bdf44="" viewBox="0 0 13 19" class="mr-3"><use data-v-525bdf44="" xlink:href="#i-location"><symbol data-v-525bdf44="" id="i-location" viewBox="0 0 13 19"><path data-v-525bdf44="" d="M6.5 0.719971C4.8424 0.719971 3.25269 1.37318 2.08058 2.53591C0.90848 3.69863 0.25 5.27563 0.25 6.91997C0.25 11.88 6.5 18.08 6.5 18.08C6.5 18.08 12.75 11.88 12.75 6.91997C12.75 5.27563 12.0915 3.69863 10.9194 2.53591C9.74732 1.37318 8.1576 0.719971 6.5 0.719971ZM6.5 10.64C5.75832 10.64 5.0333 10.4218 4.41661 10.013C3.79993 9.60428 3.31928 9.02329 3.03545 8.34355C2.75162 7.66381 2.67736 6.91584 2.82206 6.19423C2.96675 5.47263 3.3239 4.80978 3.84835 4.28953C4.3728 3.76928 5.04098 3.41499 5.76841 3.27145C6.49584 3.12791 7.24984 3.20158 7.93506 3.48314C8.62029 3.7647 9.20596 4.2415 9.61801 4.85325C10.0301 5.465 10.25 6.18422 10.25 6.91997C10.25 7.90658 9.85491 8.85277 9.15165 9.55041C8.44839 10.248 7.49456 10.64 6.5 10.64Z"></path></symbol></use></svg>
              <span v-text="contacts.address"></span>
            </p>    
          </div>
          <div class="my-md-3 pt-3 border-top">
            <p class="m-0 small">ИП МИХАЙЛЕНКО ВИТАЛИЙ ЮРЕВИЧ</p>
            <p class="m-0 small">ИНН 263408773140</p>
            <p class="m-0 small">ОГРНИП 312265131200415</p>
          </div> 

        </div>
        <div class="col-md-6 p-3">
          <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A0ea42e8cb8e003c05007279ff3677ef79fedb03312c8e5516f94a1c611918ff4&amp;source=constructor" width="100%" height="300" frameborder="0"></iframe>
        </div> 
      </div>
      <div class="d-flex py-2 px-2 lh-1 wo7 small">
        <div class="w-100">
          <span class="cursor-pointer" @click="$parent.modals.modalprivacy.show()">Политика конфиденциальности</span>          
        </div>
        <div class=" text-right">
          <a href="https://wo7.ru" target="_blank">wo7.ru</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contacts",
  props: {
    contacts: Object
   },
  methods:{
  }
};
</script>


<style scoped lang="scss">
#contacts svg {
    top: 0;
    left: 0;
    width: 16px;
    height: 16px;
    fill: #007ac2;
}

.wo7{
  background-color: #007ac2;
  color: #fff;
  a{
    color:#fff;
  }
}
</style>
