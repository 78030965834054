import Vue from "vue";
import App from "./App.vue";

const VueInputMask = require('vue-inputmask').default

Vue.config.productionTip = false;

Vue.use(VueInputMask)

new Vue({
  render: (h) => h(App),
}).$mount("#app");
