<template>
  <div id="app">
    <Header :contacts="contacts" :active_menu="site.active_menu" @menu_status="site.active_menu = $event"></Header>
    <Menu  v-show="site.active_menu" :contacts="contacts" :active_menu="site.active_menu" @menu_status="site.active_menu = $event"></Menu>
    <Info></Info>
    <Calc></Calc>
    <Faq></Faq>
    <Docs></Docs>
    <About></About>
    <Contacts :contacts="contacts"></Contacts>
    <Cookies></Cookies>
    <ModalOrder :modalorder="modalorder" :order="order"></ModalOrder>
    <ModalPrivacy></ModalPrivacy>
  </div>
</template>

<script>

import axios from 'axios'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'bootstrap/dist/css/bootstrap.min.css'
import '/src/assets/fonts/NeoSansPro/stylesheet.scss'

import Header from "./components/Header.vue"
import Menu from "./components/Menu.vue"
import Info from "./components/Info.vue"
import Calc from "./components/Calc.vue"
import ModalOrder from './components/ModalOrder.vue'
import Contacts from './components/Contacts.vue'
import Cookies from './components/Cookies.vue'
import ModalPrivacy from './components/ModalPrivacy.vue'
import Faq from './components/Faq.vue'
import Docs from './components/Docs.vue'
import About from './components/About.vue'

export default {
  name: "App",
  components: {
    Header, Menu, Info, Calc, ModalOrder, Contacts, Cookies, ModalPrivacy, Faq, Docs, About
  },
  data: function(){
    return {
      contacts:{
        phone: '8 928 311 13 97',
        email: 'info@vdgo26.ru',
        address: 'г. Ставрополь, ул. Тухачевского, 23/2 (м-рн «Перспективный»)'
      },      
      site:{
        active_menu: false
      },
      order:{
        sended: false,
        fields_has_errors: [],
      },
      modals: {
        modalprivacy: {},
        modalorder: {},
        modalcookies: {}
      },
      modalorder: {
        title: null,
        selected_items:[]
      },
    }
  },
  methods:{    
    send_order(info){
      //const devUrl = `http://localhost/composite`
      //console.log (info)
      //axios({method: 'post', url: devUrl+'/send/', info } ).then((r)=>{
        const fd = new FormData()
        fd.append('data', JSON.stringify(info))
     // axios.post(devUrl+'/send/', fd).then((r)=>{
      axios.post('/send/', fd).then((r)=>{
        // console.log (r.data)
        this.order.fields_has_errors = r.data.fields_has_errors;
        if (r.data.status == `success`){
          if(typeof(window.ym) == "function"){
              window.ym(79526611,'reachGoal','callback')        
          }
          this.order.sended = true
        }
      })
    },
    show_modalorder(title, selected_items = []){
      this.modalorder.title = title
      this.modalorder.selected_items = selected_items
      this.modals.modalorder.show()
    },
    scroll(el){
      const scrollDiv = document.getElementById(el).offsetTop
      setTimeout(()=>{
        window.scrollTo({ top: scrollDiv, behavior: 'smooth'})
      }, 150)
    },
    number_format(number, round = 0){
      if (round == 0){
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      } else {
        return number.toFixed(round).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      }
    },
  },
  created(){
    const urlParams = new URLSearchParams(window.location.search);
    const change_gasoline_counter = urlParams.get('change_gasoline_counter');
    if (change_gasoline_counter == 'true'){
      setTimeout(()=>{
        this.show_modalorder('Записаться на замену газового счетчика')
      }, 3000)
    }
  }
};
</script>

<style lang="scss">
body{
  background-image: url('/assets/bg.webp');
  background-repeat: no-repeat;
  background-position: center right;
  background-attachment: fixed;
}
#app {
  .container{
    background-color:rgba(255,255,255,.7);

  }
  $blue_color: #007ac2;
	font-family: 'Neo Sans Pro';


  .bg-blue-dotted{
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAARklEQVQYV2OUnv/3P8M/Bob37z4wYAOM0nOBCoAApwKu7rdgBQyMQAxhoQBGuAKsFgD1oSgAmiIoKABWCrOSRAVYrCHoBgDQ2CIRLVhJNwAAAABJRU5ErkJggg==);
  }

  .no-wrap{
    white-space: nowrap;
  }
  .text-blue{
    color: $blue_color;
  }
  .bg-blue{
    background-color: $blue_color;
  } 
}

.cursor-pointer{
  cursor: pointer;
}


.fade-enter-active, .fade-leave-active { transition: opacity .5s; } .fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ { opacity: 0; }
.show-enter-active, .show-leave-active { transition: all .15s; transform: translateX(0);}.show-enter, .show-leave-to { opacity: 0; transform: translateX(-400px);}
</style>
