<template>
  <div class="bg" id="docs">
    <CoolLightBox :items="gallery.items" :index="gallery.index" @close="gallery.index = null" :loop="true"></CoolLightBox>
    <div class="container px-2 py-3 overflow-hidden text-blue">
      <div class="row">
        <div class="col-12">
          <div class="row justify-content-around">
            <div class="col-12 col-md-6 col-lg-3 my-1">
              <div class="in rounded border h-100 d-flex flex-row align-items-center cursor-pointer" @click="gallery.index=0">
                <img class="m-1" src="/assets/pdf.png" alt="">
                <p class="small text-center lh-1 my-2 flex-grow-1">Типовой договор ВДГО</p>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3 my-1">
              <div class="in rounded border h-100 d-flex flex-row align-items-center cursor-pointer" @click="gallery.index=1">
                <img class="m-1" src="/assets/pdf.png" alt="">
                <p class="small text-center lh-1 my-2 flex-grow-1">Прайс на монтажные услуги</p>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3 my-1">
              <div class="in rounded border h-100 d-flex flex-row align-items-center cursor-pointer" @click="gallery.index=2">
                <img class="m-1" src="/assets/pdf.png" alt="">
                <p class="small text-center lh-1 my-2 flex-grow-1">Прайс на техическое обслуживание</p>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3 my-1">
              <div class="in rounded border h-100 d-flex flex-row align-items-center cursor-pointer" @click="gallery.index=3">
                <img class="m-1" src="/assets/pdf.png" alt="">
                <p class="small text-center lh-1 my-2 flex-grow-1">Членский билет Торгово-промышленной палаты СК</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox'

import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {
  name: "Info",
  props: { },
  components: {
    CoolLightBox
  },
  data: ()=>{
    return {
      gallery: {
        items: [
          '/files/contract.pdf', '/files/price_mounting.pdf', '/files/price_vdgo.pdf', '/files/member.pdf'
        ],
        index:null,
      }
    }
  },
  methods:{
  }
};
</script>


<style scoped lang="scss">
.in{
  transition: all ease .3s;
  background-color: #fff;

  &:hover{
    background-color: #eee;
  }
  img{
    width: 40px;
    height: 40px;

  }  
}

</style>
