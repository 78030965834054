<template>
  <div class="bg" id="info">
    <div class="container px-2 py-5 overflow-hidden text-blue">
      <div class="row">
        <div class="col-md-6">
            <h2>Своевременно заключайте договоры на техническое обслуживание газового оборудования</h2>
            <p class="mb-3">Мы выполняем техническое обслуживание внутридомового и внутриквартирного газового оборудования.</p>
            <p class="bg-salmon mb-0 p-3 text-dark rounded border-dark-warning">Согласно постановлению Правительства №410 от 14.05.2013г. отсутствие договора на техническое обслуживание ВДГО может являться основанием для прекращения подачи газа.</p>        
            <p class="h2 mt-3 mt-lg-5">Мы выполняем техническое обслуживание газового оборудования ВДГО / ВКГО</p>
            <div class="p-3 text-center">
              <button class="btn btn-warning btn-outline-dark btn-lg m-1 d-block d-md-inline buttons-b-md-w-100" @click="$parent.show_modalorder('Записаться на ВДГО / ВКГО')">Записаться на ВДГО / ВКГО</button>
              <button class="btn btn-warning btn-outline-dark btn-lg m-1 d-block d-md-inline buttons-b-md-w-100" @click="$parent.show_modalorder('Записаться на замену газового счетчика')">Записаться на замену газового счетчика</button>
            </div>
        </div>
        <div class="col-md-6 p-0 text-right">
          <img id="to_vdgo" src="/assets/to-vdgo.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Info",
  props: { },
  methods:{
  }
};
</script>


<style scoped lang="scss">
  .bg-salmon{
    background-color:#ffc9c9 !important
  }

  .border-dark-warning{
    border: 3px dashed #000!important;
  }

  @media (max-width:572px) {
    #to_vdgo{
      width: 100%;
      transform: translateX(1rem);
    }
  }

  @media (max-width:768px) {
    .buttons-b-md-w-100{
      width: 100%;
    }
  }


  
</style>
